body {
    color: #fff;
    font-family: 'Open Sans';
    background-color: #fff;
  }
  
  .banner {
      padding: 6em 0 8em 0;
      text-align: center;
    width: 100%;
    background-image: url(../../../public/assets/images/banner/hero-banner-3.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    box-shadow: inset 0 0 0 2000px rgba(0,0,0,0.5);
  }
  
  .border {
      width: 60%;
      margin: 0 auto;
      height: 47px;
      border: 2px solid rgb(238, 18, 67);
    border-bottom: 0;
  }
  .banner h2 {
      margin: 0;
      font-size: 3em;
      color: rgb(238, 18, 67);
      text-align: center;
      font-weight: 300;
      text-transform: uppercase;
      letter-spacing: 0.5em;
      padding: 0.5em 0 0 0;
  }
  
  .banner p {
      color: rgb(238, 18, 67);
      font-size: 1em;
      font-weight: bold;
      margin: 1em 0 0 0;
      line-height: 2em;
      letter-spacing: 2px;
    padding: 0 0 2em 0;
  }
  
  .border-bottom {
      border-top: 0;
      border-bottom: 2px solid #fff !important;
  }