.namtext{
    color: white;
    text-decoration: none;
}
.tablees-container {
    /* max-height: 700px; Adjust the maximum height as needed */
    overflow-y: auto;
    /* align-items: center; */
    /* justify-content: center; */
  }

  /* Loader.css */

.loader-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.9);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure the loader appears on top of other elements */
  }
  
  .loader {
    border: 8px solid #f3f3f3; /* Light grey */
    border-top: 8px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 250px;
    height: 250px;
    animation: spin 1s linear infinite;
  }

  .loaderimg{
    border-radius: 50%;
  }
  
  /* @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  } */
  